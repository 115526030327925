.createTemplateContainer{
    height: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 30px;
}
.draggable-component{
    z-index: 111;
}
#rightPageViewContainerId{
    border: 1px dashed gray;
    width: 400px !important;
}