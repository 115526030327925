.assistDoctorContainer{
    margin: 20px 10px 0px;
}
.assistDoctorHeading{
    font-size: 20px;
    color: black;
}
.assistDoctorButtonContainer{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
.assistDoctorInfoText{
    font-size: 16px;
    color: #ed6c02;
    font-style: italic;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
}